import React, { Component } from "react";
import { SatelliteInstances } from "app/satellite";
import { Box, Heading, Flex, Label } from "primitives";
import { SaveButton, InputField } from "components";
import { FeedbackStatus } from "app/feedback/models";
import { getTLE } from 'app/satelliteDefinition';

export class ConstellationEdit extends Component {
  state = {};

  componentDidMount() {
    this.getConstellation();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.selected?.id !== prevProps?.selected?.id &&
      this.props.selected?.id
    ) {
      this.props.history.push(`/constellation/${this.props.selected?.id}`);
    }
  }

  getConstellation() {
    const { id } = this.props.match.params;
    this.props.getConstellation(id).then((data) => {
      if (data?.satelliteInstances) {
        data.satelliteInstances = data.satelliteInstances?.map((satelliteInstance) => {
          getTLE(satelliteInstance.id).then((tle) => {
            if (tle) {
              satelliteInstance.tle = tle;
            }
          });
        })
        this.setState({ ...data });
      }
    });
  }

  onChange = (event) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  };

  submit = (e) => {
    e.preventDefault();
    const { satelliteInstances, ...data } = this.state;
    this.props
      .updateConstellation(data)
      .then(() => {
        this.props.history.push("/constellation");
      })
      .catch((e) => {
        this.props.setFeedback("Unauthorized", FeedbackStatus.ERROR);
      });
  };

  render() {
    const { selected } = this.props;
    if (!selected) return null;

    return (
      <Box color="text.default" data-testid="ConstellationEdit" mx={3}>
        <Flex mb={2}>
          <Heading display={1}>{`Edit Constellation`}</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3} mb={2}>
          <form onSubmit={(e) => this.submit(e)}>
            <Flex flexDirection="column" mb={3}>
              <Label>ID</Label>
              <InputField disabled={true} defaultValue={selected.id} />
            </Flex>
            <Flex flexDirection="column" mb={3}>
              <Label>Name:</Label>
              <InputField
                name="name"
                fullWidth
                defaultValue={selected.name}
                required={true}
                onChange={(e) => this.onChange(e)}
              />
            </Flex>
            <Flex justifyContent="flex-start" mb={2}>
              <SaveButton type="submit">Save</SaveButton>
            </Flex>
          </form>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SatelliteInstances
            constellation={selected}
            onChange={() => this.getConstellation()}
          />
        </Flex>
      </Box>
    );
  }
}
