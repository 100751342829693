import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Flex } from "primitives";
import { Form } from "app/shared";
import { processFormData, mergeFormData } from "app/passageManager/utils";
import { bookerSchemaEdit, createbookerUISchema } from "./bookerSchema";

interface BookerFormProps {
  type: string;
  readonly: boolean;
  submit: any;
  formRef: any;
  config: any;
}
export const BookerForm = ({
  type,
  readonly,
  submit,
  formRef,
  config
}: BookerFormProps) => {
  const [selectedConfigId, setSelectedConfigId] = useState(config);
  const [lastFormData, setFormData] = useState<any>({});
  const [enableQuickRange, setEnableQuickRange] = useState<any>(false);
  const [initialFormData, setInitialFormData] = useState<any>({});
  const { groundStationConfigs } = useSelector((s: any) => s.passageManager);

  const satellites = useSelector((s: any) => s.constellations.dashboard);
  const groundStations = useSelector(
    (s: any) => s.groundStations?.groundStations
  );

  const { data: configs } = useSelector(
    (s: any) => s.passageManager.bookerConfigs
  );

  const onChange = useCallback(
    ({ formData }) => {
      const configId = formData?.configuration || selectedConfigId?.id;
      setSelectedConfigId(configId);
      const selectedConfig = configs?.find((pc: any) => pc.id === configId);
      const formattedData = processFormData(
        selectedConfig,
        groundStationConfigs
      );
      const mergedFormData = {
        ...mergeFormData(formData, formattedData),
        ...formattedData,
        daterange: {
          from: formData.daterange.from || formattedData.daterange.from,
          to: formData.daterange.to || formattedData.daterange.from
        }
      };
      setFormData(mergedFormData);
    },
    [configs, satellites, groundStations, selectedConfigId]
  );

  const { sats, gs } = getSatGSFromFrom(initialFormData, lastFormData);
  return (
    <Flex flexDirection="column" bg="fill.0" p={5}>
      <Form
        ref={formRef}
        noValidate
        id="passage-manager"
        formData={lastFormData}
        schema={bookerSchemaEdit(
          sats,
          gs,
          type,
          configs,
          selectedConfigId,
          enableQuickRange
        )}
        uiSchema={createbookerUISchema(readonly)}
        disabled={false}
        onSubmit={(
          form: { formData: any },
          e: React.FormEvent<HTMLInputElement>
        ) => {
          return submit(e, form.formData);
        }}
        onChange={(form: any) => onChange(form)}
      >
        <></>
      </Form>
    </Flex>
  );
};

function getSatGSFromFrom(initialFormData: any, lastFormData: any) {
  const GsBySatMap =
    initialFormData?.groundStationsBySatellite ||
    lastFormData?.groundStationsBySatellite;

  const gs = [
    ...new Set(
      GsBySatMap?.map((m: any) => ({
        groundStationName: m.groundStations
      })).flat()
    )
  ];
  const sats = [
    ...new Set(GsBySatMap?.map((m: any) => ({ label: m.satellite })))
  ];

  return { gs, sats };
}
