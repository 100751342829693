const parseOptions = (configOptions: any) => {
  if (configOptions && typeof configOptions === "string") {
    try {
      return JSON.parse(configOptions);
    } catch (error) {
      console.log("TURBO ~ Error parsing histogram config options");
    }
  }
  return configOptions;
};

const getOptionOrDefault = (option: any, defaultValue: any, obj: any) => {
  // const obj = this.configOptions
  if (Object.prototype.hasOwnProperty.call(obj, option)) {
    return obj[option];
  } else {
    return defaultValue;
  }
};

const findObjectByProperty = (obj: any, targetId: any, prop: any) => {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const item = obj[key];
      if (
        item[prop] === targetId ||
        (prop === "id" && parseInt(item[prop]) === targetId)
      ) {
        return item;
      }
    }
  }
  return null;
};

const cleanData = (data: any[], valueTypes: any) => {
  const getValue = (i: any) => i.readings[0].value[0].value;
  const { invalidDataSources, validDataSources, maxValidValue } = valueTypes;
  const max = Math.max(...data.map(getValue));
  const remappedInvalidValues = (data: any[]): any[] => {
    return data.map((obj) => {
      if (
        invalidDataSources.map((i: any) => getValue(i)).includes(getValue(obj))
      ) {
        return {
          ...obj,
          readings: obj.readings.map((reading: any) => ({
            ...reading,
            value: [
              {
                ...reading.value[0],
                value: Math.floor(maxValidValue + 1000000)
              }
            ]
          }))
        };
      }
      return obj;
    });
  };
  return remappedInvalidValues(data);
};

const scaleDataSourceReading = (
  data: any[],
  scaleFn: (value: number) => number,
  widgetType?: string
) => {
  if (!data && !Array.isArray(data)) return data;
  return data.map((obj) => {
    return {
      ...obj,
      readings: obj.readings.map((reading: any, i: number) => {
        if (
          reading?.label === "alert-info" ||
          reading?.label?.includes("alert-info")
        ) {
          return reading;
        }
        // Line chart data is a dataframe with extracted readings, appling scaling function directly on readings array
        if (widgetType === "RealTimeGraph") {
          // ignore first element which is time series for axis
          if (i === 0) {
            return reading;
          }
          return Array.isArray(reading) && reading?.map(scaleFn);
        }
        return {
          ...reading,
          value: reading.value.map((val: any) => {
            return {
              ...val,
              value: scaleFn(val.value)
            };
          })
        };
      })
    };
  });
};

// Handle scaling of simpleValue separately as it is not using graphModel
const scaleSimpleValue = (data: any, options: any) => {
  if (!Array.isArray(data.readings)) {
    return data;
  }
  const scaledReadings = {
    dataSource: data.dataSource,
    readings: data.readings.map((reading: any, i: number) => {
      return {
        ...reading,
        value: reading.value.map((val: any) => {
          return {
            ...val,
            value: applyTransform(val.value, options.scale)
          };
        })
      };
    })
  };
  return scaledReadings;
};

const applyTransform = (dsValue: any, scaleOptions: any) => {
  const transformFun = scaleOptions?.transform;
  if (!transformFun || isNaN(dsValue)) return dsValue;
  const regex = /([+\-*/])\s*(\d+\.?\d*)/;
  // add additional validations here
  const matches = transformFun && transformFun.match(regex);
  const operator = Array.isArray(matches) ? matches[1] : null;
  const operand = Array.isArray(matches) ? matches[2] : null;

  const transform = (dsValue: number, operand: number, operator: string) => {
    switch (operator) {
      case "*":
        return dsValue * operand;
        break;
      case "/":
        return dsValue / operand;
        break;
      case "-":
        return dsValue - operand;
        break;
      case "+":
        return dsValue + operand;
        break;
      default:
        throw Error("error parsing transform function");
        break;
    }
  };
  if (dsValue && operand && operator) {
    const transformed = transform(dsValue, operand, operator);
    return transformed;
  }
  return dsValue;
};

export {
  parseOptions,
  findObjectByProperty,
  getOptionOrDefault,
  scaleDataSourceReading,
  applyTransform,
  scaleSimpleValue
};
