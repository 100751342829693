import { GET_LIST } from "app/network";
import { passageManagerDataProvider } from "app/network/dataProvider";

const THIRTY_MINUTES_IN_MS = 31 * 60 * 1000;
const THREE_WEEKS_IN_MS = 3 * 7 * 24 * 60 * 60 * 1000;

export const getDefaultStartTime = () => new Date(Date.now() + THIRTY_MINUTES_IN_MS).toISOString();

const defaultEndTime = new Date(Date.now() + THREE_WEEKS_IN_MS).toISOString();

export const getPassageCandidatesForConfig = (
  configId: any,
  startTime?: string,
  endTime?: string
) => {
  if (!configId) return { data: [] };
  const effectiveStartTime =
    startTime && new Date(startTime) > new Date()
      ? startTime
      : getDefaultStartTime();

  const effectiveEndTime =
    endTime && new Date(endTime) > new Date() ? endTime : defaultEndTime;

  const params = new URLSearchParams({
    availability: "available",
    bookingConfigurationID: configId?.toString(),
    startTime: effectiveStartTime,
    endTime: effectiveEndTime
  });

  const query = `candidates?${params.toString()}`;

  return passageManagerDataProvider(GET_LIST, query);
};
