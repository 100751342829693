import { requestManager as req } from "./requestManager";
import { stringify } from "qs";

export const fetchJson = async (url, options) => {
  if (!options) {
    throw Error("Invalid HTTP options");
  }

  // If a request is currently being made to same endpoint, cancel previous request and send current request 
  if (req.inProgress(url, options)) {
    req.cancel(url)
  }

  if (req.isCached(url, options)) {
    return req.getCached(url, options)
  }

  try {
    const controller = new AbortController();
    // add current request url to requestManager map to track repeated requests
    req.add(url, controller, options)


    // pass signal to current fetch to make it abortable
    const response = await fetch(url, { ...options, signal: controller.signal })
    const text = await response.text()

    return req.handleResponse(response, text, url, options)

  } catch (error) {
    // aborted requests will throw user generated DOMexception, handle them here 
    req.handleError(error, url)
    return { error }
  }

};

export const queryParameters = stringify;
