import React from "react";
import { Switch, Route } from "react-router-dom";
import { SatelliteDefinitionList } from "./components/SatelliteDefinitionList";
import { SatelliteDefinitionEditContainer } from "./containers/SatelliteDefinitionEditContainer";
import { SatelliteDefinitionCreateContainer } from "./containers/SatelliteDefinitionCreateContainer";

export const SatelliteDefinitionRouters = () => (
  <>
    <Switch>
      <Route
        path="/satellite-definition/create"
        exact
        component={SatelliteDefinitionCreateContainer}
      />
      <Route
        path="/satellite-definition/:id"
        component={SatelliteDefinitionEditContainer}
      />
      <Route path="/satellite-definition" component={SatelliteDefinitionList} />
      <Route path="/satellites/tle" component={() => (<>NOT IMPLEMENTED!</>)} />
    </Switch>
  </>
);
