import React, { useState, useEffect } from "react";
import { Box, Text } from "primitives";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DownloadButton
} from "components";
import { PaginationSimple } from "app/shared";
import { BookerConfiguration } from "../../../models";
import { getBookerConfigs as getConfigs, setConfig } from "../../../actions";
import { useSelector, useDispatch } from "react-redux";
import { ConfigDelete } from "../ConfigForm/ConfigDelete";
import { ConfigSatGSMap } from "./ConfigGsBySatMap";
import styled from "styled-components";
import { ConfigEdit } from "../ConfigForm/ConfigEdit";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { saveInLocalStorage } from "app/passageManager/utils";

export const ConfigsTable = () => {
  const { data: bookerConfigs, pagination } = useSelector(
    (s: any) => s.passageManager.bookerConfigs
  );

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(pagination?.total);

  useEffect(() => {
    dispatch(getConfigs({ page, pageSize }));
  }, [page, pageSize, pagination?.total]);

  return (
    <>
      <Box
        data-testid="passage-manager-config-list"
        className="passage-manager-list"
        mx={3}
      >
        <Table>
          <TableHead>
            <TableRow bg="fill.0">
              <TableCell width="2%">ID</TableCell>
              <TableCell width="10%">Name</TableCell>
              <TableCell width="15%">Description</TableCell>
              <TableCell width="15%"> Satellites </TableCell>
              <TableCell width="15%"> GroundStations </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(bookerConfigs) &&
              bookerConfigs.map((config: BookerConfiguration) => {
                return (
                  <TableRow key={config.id}>
                    <TableCell> {config.id} </TableCell>
                    <WrappedTableCell align="center">
                      <Text bold> {config.name} </Text>
                    </WrappedTableCell>
                    <WrappedTableCell align="center">
                      {config.description}
                    </WrappedTableCell>
                    <TableCell width="1%">
                      <ConfigSatGSMap
                        satellites={Object.keys(
                          config?.groundStationsBySatellite
                        )}
                      />
                    </TableCell>
                    <TableCell width="25%" maxWidth={"20px"}>
                      <ConfigSatGSMap
                        groundstations={Object.values(
                          config?.groundStationsBySatellite
                        )}
                      />
                    </TableCell>
                    <TableCell width="1%" align="center">
                      <Link to="/passage-manager-candidates">
                        {
                          <DownloadButton
                            onClick={() => {
                              dispatch(setFeedback(`Fetching booked & available passages`, FeedbackStatus.SUCCESS));
                              saveInLocalStorage("bookingConfig", config);
                              return dispatch(setConfig(config));
                            }}
                            ml={2}
                          >
                            {"PASSAGES"}
                          </DownloadButton>
                        }
                      </Link>
                    </TableCell>
                    <TableCell width="1%" align="center">
                      <ConfigEdit config={config} />
                    </TableCell>

                    <TableCell width="1%" align="center">
                      <ConfigDelete
                        configId={config.id}
                        onChange={() => dispatch(getConfigs())}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <PaginationSimple
          total={total}
          page={page + 1}
          pageSize={pageSize}
          nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
          previousPageUrl={page - 1}
          onChange={(newPage: string | number) => {
            setPage(Number(newPage));
            setPageSize(pageSize);
          }}
          onPageSizeChange={(newPageSize: number) => {
            setPage(0);
            setPageSize(Number(newPageSize));
          }}
        />
      </Box>
    </>
  );
};

const WrappedTableCell = styled(TableCell)`
  white-space: normal;
  word-wrap: break-word;
`;
