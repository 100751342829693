import React from "react";
import { Box, Flex, Relative, Absolute, Text } from "primitives";

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <Relative height="100%" minHeight="200px">
    <Absolute
      top="0"
      left="0"
      bottom="0"
      right="0"
      bg="fill.0"
      bgOpacity={2}
      m="1%"
      zIndex={2}
    >
      <Box width={1} height="100%">
        <Flex alignItems="center" justifyContent="center" height="100%">
          {children}
        </Flex>
      </Box>
    </Absolute>
  </Relative>
);

export const Loading = () => (
  <Wrapper>
    <span>Loading...</span>
  </Wrapper>
);

export const LoadingError = (props: any) => {
  const { httpErrorStatus } = props;
  const errorText =
    httpErrorStatus === 401 || httpErrorStatus === 403
      ? "FORBIDDEN: Not authorized to access this resource"
      // cancelled requests are not errors
      : "";

  return (
    <Wrapper>
      <Text color="text.danger">{errorText} </Text>
    </Wrapper>
  );
};
