import React from "react";
import { InputBase } from "app/shared";

export const schemaCreate = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    classes: {
      type: "array",
      hasRemove: true,
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name"
          }
        }
      }
    }
  }
};

export const uiSchemaCreate = {
  name: { "ui:widget": InputBase },
  classes: {
    items: {
      name: { "ui:widget": InputBase }
    }
  }
};

export const schemaEdit = {
  type: "object",
  properties: {
    id: {
      type: "number",
      title: "Id",
      kind: "constant"
    },
    name: {
      type: "string",
      title: "Name"
    },
    classes: {
      type: "array",
      hasRemove: true,
      items: {
        type: "object",
        properties: {
          id: {
            type: "number"
          },
          name: {
            type: "string",
            title: "Name"
          }
        }
      }
    },
    tle: {
      type: "string",
      title: "TLE"
    },
  }
};

export const uiSchemaEdit = {
  id: {
    "ui:widget": (props: any) => {
      return (
        <InputBase
          {...props}
          disabled={true}
        />
      );
    },
  },
  name: { "ui:widget": InputBase },
  classes: {
    items: {
      id: { "ui:widget": "hidden" },
      name: { "ui:widget": InputBase }
    }
  },
  tle: {
    "ui:widget": (props: any) => {
      return (
        <InputBase
          {...props}
          multiline={true}
          rows={3}
        />
      );
    },
    classNames: "tle-input"
  }
};
