import React, { useCallback } from "react";
import { SuspenseMutation } from "app/network";
import { Toggler, Spinner } from "components";
import { CancelButton, EditButton } from "components";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Text
} from "primitives";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "app/shared";
import { updateOnePassage } from "app/passageManager/actions";
import { bookSchemaEdit, bookSchemaUISchemEdit } from "./bookCandidateSchema";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { Candidate as Passage } from "app/passageManager/models";

interface UpdatePassageProps {
  passage?: Passage;
  bookable?: boolean;
  bookingConfigId?: any;
}

export const UpdatePassage = ({
  passage,
  bookable,
  bookingConfigId
}: UpdatePassageProps) => {
  const dispatch = useDispatch();
  const { status, presets } = useSelector((s: any) => s.passageManager);
  const presetsBySat = !presets ? [] : Object.keys(presets)?.filter(satId => Number(satId) === passage?.satelliteID)
    .flatMap(key => presets[key]);

  const loading = status?.book === "loading";
  const bookFormData = {};
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    onToggle: any
  ) => {
    e.preventDefault();
    const selectedPreset = presetsBySat?.find(p => p.id === formData.presets);
    const newPreset = {
      satelliteID: passage?.satelliteID,
      passageID: passage?.passageID,
      providerID: passage?.additionalInfo?.provider?.providerId,
      presets: {
        providerName: selectedPreset.providerName,
        name: selectedPreset.name,
        id: selectedPreset.id,
        configuration: selectedPreset.configuration,
      }
    }
    if (!passage?.additionalInfo?.provider?.providerId) {
      dispatch(setFeedback("Provider id is missing!", FeedbackStatus.ERROR));
      return onToggle();
    }
    const result = await dispatch(updateOnePassage(newPreset, passage.passageID, bookingConfigId));
    if (result?.length || !loading) {
      dispatch(setFeedback(`Preset "${selectedPreset?.name}" set to passage ${passage.passageID} successfully.`, FeedbackStatus.SUCCESS));
      onToggle();
    }
  };

  const transformData = (formData: any) => {
    return formData;
  };

  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <EditButton
            onClick={onToggle}
            disabled={bookable}
            data-testid="passage-edit-btn"
          >
            EDIT
          </EditButton>
          <Dialog
            open={toggled}
            maxWidth="lg"
            data-testid="passage-manager-edit"
          >
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>
                      Update passage with id: {passage?.passageID}?
                    </DialogTitle>
                    <DialogContent>
                      <Flex alignItems="center">
                        <Form
                          id="passage-manager"
                          data-testid="candidate-book-form"
                          formData={bookFormData}
                          schema={bookSchemaEdit(presetsBySat)}
                          uiSchema={bookSchemaUISchemEdit}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: any },
                            e: React.FormEvent<HTMLInputElement>
                          ) => {
                            return action(() =>
                              submit(e, form.formData, onToggle)
                            );
                          }}
                          onChange={(form: any) => onChange(form)}
                        >
                          <></>
                        </Form>
                      </Flex>
                    </DialogContent>
                    <DialogActions>
                      <CancelButton onClick={onToggle}>CANCEL</CancelButton>
                      <Button
                        form="passage-manager"
                        type="submit"
                        ml={2}
                        data-testid="book-btn"
                      >
                        {loading ? (
                          <Flex flexDirection="row" mx={2}>
                            <Spinner size={14} />
                            <Text color="text.default" mx={2}>
                              UPDATING....
                            </Text>
                          </Flex>
                        ) : (
                          <Text color="text.default"> UPDATE PASSAGE </Text>
                        )}
                      </Button>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

