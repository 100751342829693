import uPlot from "uplot";
import { GraphDataModel } from "../../shared";
import { legendTooltipPlugin, thresholdsPlugin, zoomPlugin } from "../plugins";
import { clickListener } from "../plugins/alert/click-listener";
import {
  alertInfoSeriesConfig,
  alertSeriesConfig
} from "../plugins/alert/config";
import { cursorTracker } from "../plugins/alert/cursor-tracker";
import { legendSettings } from "../plugins/alert/legend";

const CUSTOM_DATE_FORMATS = [
  [31536000, "{YYYY}", null, null, null, null, null, null, 1],
  [2419200, "{MMM}", "\n{YYYY}", null, null, null, null, null, 1],
  [86400, "{M}/{D}", "\n{YYYY}", null, null, null, null, null, 1],
  [3600, "{HH}:{mm}", "\n{M}/{D}/{YY}", null, "\n{M}/{D}", null, null, null, 1],
  [60, "{HH}:{mm}", "\n{M}/{D}/{YY}", null, "\n{M}/{D}", null, null, null, 1],
  [
    1,
    ":{ss}",
    "\n{M}/{D}/{YY} {HH}:{mm}",
    null,
    "\n{M}/{D} {HH}:{mm}",
    null,
    "\n{HH}:{mm}",
    null,
    1
  ],
  [
    0.001,
    ":{ss}.{fff}",
    "\n{M}/{D}/{YY} {HH}:{mm}",
    null,
    "\n{M}/{D} {HH}:{mm}",
    null,
    "\n{HH}",
    null,
    1
  ]
];

export const COLORS = [
  "#0f83ab",
  "#faa43a",
  "#fd6868",
  "#53cfc9",
  "#a2d925",
  "#decf3f",
  "#734fe9",
  "#cd82ad",
  "#006d92",
  "#de7c00",
  "#f33232",
  "#3f9a80",
  "#53c200",
  "#d7af00",
  "#4c26c9",
  "#d44d99",
  "#845EC2",
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
  "#F9F871",
  "#F9F871",
  "#FBEAFF",
  "#00C9A7",
  "#4D8076",
  "#D5CABD",
  "#F3C5FF",
  "#D8EFF8",
  "#9B89B3",
  "#00D2FC"
];
const MARGIN = 8;

const DRAW_STYLE = {
  line: 0,
  bars: 1,
  points: 2,
  barsLeft: 3,
  barsRight: 4
};
enum PLOT_TYPE {
  SCATTER = "points",
  LINE = "line"
}

export const graphType = (i: number, model: GraphDataModel) => {
  const { lineArea, showPoints, plotType, thickness } =
    model.getLineChartConfig();
  const graphType = {
    width: thickness,
    points: {
      show: showPoints,
      fill: showPoints ? COLORS[i as number] : "#fff"
    },
    spanGaps: plotType === PLOT_TYPE.LINE,
    drawStyle:
      plotType === PLOT_TYPE.LINE ? DRAW_STYLE.line : DRAW_STYLE.points,
    ...(plotType === PLOT_TYPE.LINE &&
      !lineArea && { stroke: COLORS[i as number] }),
    ...(plotType === PLOT_TYPE.LINE &&
      lineArea && {
        stroke: COLORS[i as number],
        fill: `${COLORS[i as number]}1A`
      })
  };
  return graphType;
};

const seriesSettings = (model: GraphDataModel) => {
  const { labels, invalidValues } = model.getLineChartData();
  const hasInvalids = Object.keys(invalidValues).length > 0;
  const { min, max } = model.getLineChartConfig();

  const settings = labels.map((label: string, i: number) => {
    const isAlert = label.includes("alerts");
    const isAlertInfo = label === "alert-info" || label.includes("alert-info");
    if (isAlertInfo) {
      return alertInfoSeriesConfig(label);
    }
    if (isAlert) {
      return alertSeriesConfig(label);
    }
    return {
      auto: true,
      label: label,
      spanGaps: true,
      //TODO: Improve how invalid datasource values are handled
      value: (self: any, v: any, sidx: any, didx: any) => {
        const valueIsInvalid = isInvalid(v, min, max);
        const invalidValueIndex =
          valueIsInvalid &&
          v
            .toString()
            .replace(Math.sign(v) === -1 ? min : max, "")
            .replace("000", "")
            .replace("-", "");

        if (hasInvalids) {
          console.warn("Current date range has invalid values:", invalidValues);
          const legend = document.querySelector(".u-legend");
          const noteElExists = document.querySelector(".u-legend > span");
          const noteEl = document.createElement("span");
          noteEl.classList.add("invalid-note");
          const noteTxt = "NOTE: invalid values found outside y-axis range!";
          noteEl.appendChild(document.createTextNode(noteTxt));
          !noteElExists && legend?.appendChild(noteEl);
        }

        const legendValues = self.root.querySelectorAll(".u-value");
        if (valueIsInvalid && legendValues[sidx]) {
          legendValues[sidx].style.color = "red";
        } else if (legendValues[sidx]) {
          legendValues[sidx].style.color = "white";
        }
        const hasValue = typeof v !== "object" && v !== null && v !== undefined;
        const customValue =
          hasValue && isNaN(v)
            ? "NaN"
            : valueIsInvalid
            ? invalidValues[invalidValueIndex] || v
            : typeof v === "object"
            ? null
            : v;

        return customValue;
      },
      ...graphType(i, model),
      points: {
        show: (
          self: uPlot,
          seriesIdx: number,
          startIdx: number,
          endIdx: number,
          _: any
        ) => drawCustomPoint(self, seriesIdx, startIdx, endIdx, _, min, max)
      }
    };
  });
  const isInfo = (i: any) => i.label?.startsWith("alert-info") || false;
  const ordered = [
    ...settings.filter((i: any) => !isInfo(i)),
    ...settings.filter((i: any) => isInfo(i))
  ];
  return ordered;
};

const cursorSettings = () => {
  return {
    drag: { setScale: true },
    points: {
      // take the current LineChart(self),and calculate diameter of circle of hover point for each data series
      size: (self: any, seriesIdx: number) =>
        self.series[seriesIdx].points.size * 1.8,
      width: (self: any, _: any, size: number) => size / 4,
      stroke: (self: any, seriesIdx: number) =>
        `${self.series[seriesIdx].points.stroke(self, seriesIdx)}90`
      // fill: (u: Uplot, seriesIdx: any) => "#fff"
    },
    sync: { key: "0" },
    x: false,
    y: false
  };
};

const setYAxisRange = (model: GraphDataModel) => {
  const { min, max } = model.getLineChartConfig();
  // if manual min, max are set in config including min or max as "auto", use those, otherwise figure it out automatically ( useful for testing with satsim)
  if (min !== null || max !== null) {
    return {
      range: (_: any, _min: any, _max: any) => {
        // add padding to show max values in smaller widgets
        const paddingTop = _max * 0.1;
        const __min = min === null ? _min : Number(min);
        const __max = max === null ? _max : Number(max);
        return [__min, __max + paddingTop] as any;
      }
    };
  }
  console.warn(
    `using auto range for min:${min} max:${max}. Set both min & max to enable manual range`
  );
  return { auto: true };
};

export const generateOptions = (model: GraphDataModel) => {
  const {
    w,
    h,
    showLegend,
    showLegendAsToolTip,
    to,
    from,
    limits,
    unit,
    legendMargin
  } = model.getLineChartConfig();
  const options = {
    hooks: {
      setCursor: [cursorTracker, clickListener]
    },
    class: "canvas-linechart",
    pxAlign: 0,
    legend: legendSettings(showLegend, showLegendAsToolTip),
    width: w - MARGIN,
    height: showLegend ? h - legendMargin : h,
    cursor: cursorSettings(),
    scales: {
      x: {
        time: true,
        // x-axis date ranges are controlled globally by timeController (in ms)
        range: () =>
          [
            new Date(from).getTime() / 1000,
            new Date(to).getTime() / 1000
          ] as any
      },
      y: { ...setYAxisRange(model) }
    },
    axes: [
      {
        stroke: "#c7d0d9",
        grid: {
          width: 1 / devicePixelRatio,
          stroke: "#2c3235"
        },
        ticks: {
          width: 1 / devicePixelRatio,
          stroke: "#2c3235"
        },
        // Use custom date template for x-axis for various ranges
        values: CUSTOM_DATE_FORMATS,
        space: 75
      },
      {
        label: "",
        values: (self: uPlot, ticks: any[]) => {
          return ticks.map(
            (rawValue: string | number) => `${rawValue} ${unit}`
          );
        },
        stroke: "#c7d0d9",
        grid: {
          width: 1 / devicePixelRatio,
          stroke: "#2c3235"
        },
        ticks: {
          show: true,
          size: 1,
          width: 1 / devicePixelRatio,
          stroke: "#2c3235"
        }
      }
    ],
    tzDate: (ts: number) => uPlot.tzDate(new Date(ts * 1e3), "UTC"),
    series: [
      {
        label: "",
        value: "{YYYY}-{MM}-{DD} {HH}:{mm}:{ss}:{fff}"
      },
      ...seriesSettings(model)
    ],
    plugins: [
      thresholdsPlugin(limits),
      zoomPlugin(),
      ...(showLegendAsToolTip ? [legendTooltipPlugin()] : []),
      update()
    ]
  };
  return {
    ...options
  };
};

function update() {
  return {
    hooks: {
      setCursor: [
        (u: any) => {
          const { left, top } = u.cursor;
        }
      ]
    }
  };
}

function drawCustomPoint(
  self: uPlot,
  seriesIdx: number,
  startIdx: number,
  endIdx: number,
  _: any,
  configMin: any,
  configMax: any
) {
  const { ctx } = self;
  const { scale } = self.series[seriesIdx];
  const min = self.scales.y.min;
  const max = self.scales.y.max;
  ctx.save();
  const radius = 2;
  const invalidPointColor = "#ff0000b5";

  let point = startIdx;
  while (point <= endIdx) {
    const currentValue = self.data[seriesIdx][point];
    const _min = configMin !== null ? configMin : min;
    const _max = configMax !== null ? configMax : max;
    const valueIsInvalid = isInvalid(currentValue, _min, _max);
    const notNil = currentValue !== null && currentValue !== undefined;
    const invalid = () => {
      if (notNil) {
        return currentValue > Number(max) || currentValue < Number(min);
      }
      return false;
    };

    const currentValueIsNaN = (notNil && isNaN(currentValue)) || valueIsInvalid;
    // if currentValue is "NaN", plot it on min line as well
    const remappedValue = remapInvalidValueToMinMax(
      valueIsInvalid,
      currentValue,
      _min,
      _max
    );
    const _currentValue = currentValueIsNaN
      ? remappedValue
      : currentValue === 0
      ? 0
      : currentValue || "";

    const x = Math.round(self.valToPos(self.data[0][point], "x", true));
    const y = Math.round(
      self.valToPos(Number(_currentValue), scale as string, true)
    );

    if (currentValue !== null) {
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.strokeStyle = valueIsInvalid
        ? invalidPointColor
        : COLORS[seriesIdx - 1];
      ctx.stroke();
      ctx.fillStyle = valueIsInvalid
        ? invalidPointColor
        : `${COLORS[seriesIdx - 1]}`;
      ctx.fill();
    }
    point++;
  }
}

function drawPoint(ctx: any, x: number, y: number) {
  ctx.beginPath();
  ctx.arc(x, y, 40, 0, 2 * Math.PI);
  ctx.stroke();
}

function isInvalid(v: any, min: any, max: any) {
  if (v !== null) {
    return (
      (min !== null && !isNaN(Number(min)) && v < Number(min)) ||
      (max !== null && !isNaN(Number(max)) && v > Number(max))
    );
  }
  return false;
}

function remapInvalidValueToMinMax(
  valueIsInvalid: any,
  currentValue: any,
  min: any,
  max: any
) {
  if (valueIsInvalid && currentValue !== null) {
    const _min = Number(min);
    const _max = Number(max);
    const valueIsLessThanMin = currentValue < _min;
    const valueIsGreaterThanMax = currentValue > _max;
    if (valueIsLessThanMin) {
      return _min;
    } else if (valueIsGreaterThanMax) {
      return _max;
    } else return currentValue;
  }
  return currentValue;
}
