
import {
    visibilityDataProvider,
    GET_ONE,
    UPDATE
} from "app/network";

// v2
export const getTLEBySatId = async (satelliteID: string) => {
    if (!satelliteID) return null;
    try {
        const { data } = await visibilityDataProvider(GET_ONE, `satellites/${satelliteID}/tle`);
        return data;
    } catch (error) {
        console.log("getTLE~ error:", error)
        return null;
    }
};

export const setTLEBySatId = async (satelliteID: string, data: any) => {
    if (!satelliteID) return null;
    try {
        const { data: responseData } = await visibilityDataProvider(UPDATE, `satellites/${satelliteID}/tle`, {
            data
        });
        return responseData;
    } catch (error) {
        console.log("setTLE ~ error:", error)
        return null;
    }
};
