import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { createSelector } from "reselect";
import { PlanningSystemTimeline } from "app/planningSystem/components/Timeline";
import { passageToTimeline } from "app/planningSystem/utils/helpers";
import {
  getTimelineAction,
  getCalendarTimelineAction
} from "app/planningSystem/actions";
import {
  FetchTimelineParams,
  FetchTimelineResponse
} from "app/planningSystem/models";

const getPassages = (state: any) => state.visibilityWindow.passages;
const getCalendarPassages = createSelector([getPassages], (passages) =>
  passageToTimeline(passages)
);

const mapStateToProps = (state: any) => ({
  availableSatellites: state.constellations.dashboard,
  calendarTimeline: state.planningSystem.calendarTimeline,
  passages: getCalendarPassages(state)
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getTimeline: (params: FetchTimelineParams) =>
      dispatch(getTimelineAction(params)),
    getCalendarTimeline: (timelines: FetchTimelineResponse) =>
      dispatch(getCalendarTimelineAction(timelines))
  };
};

export const PlanningSystemTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningSystemTimeline);
