interface DateFormats {
  [name: string]: string;
}

export const dateFormats: DateFormats = {
  short: "DD MMM YYYY",
  long: "YYYY-MM-DD HH:mm:ss",
  time: "HH:mm:ss"
};

/**
 * Formats a date into a specified UTC format.
 *
 * @function utcFormatter
 * @param {Date | number | string} date - The date to be formatted. It can be a `Date` object, a timestamp (number), or an ISO string.
 * @param {string} format - The format string. Supported placeholders:
 *  - "DD MMM YYYY"
 *  - "YYYY-MM-DD HH:mm:ss"
 *  - "HH:mm:ss"
 * @returns {string} - The formatted UTC date string.
 *
 * @example
 * // Example usage
 * utcFormatter(new Date(), "YYYY-MM-DD HH:mm:ss");
 * // Output: "2024-12-19 14:30:00" (depends on the current UTC time)
 *
 * @throws {Error} If the input `date` cannot be parsed into a valid `Date`.
 */
export const utcFormatter = (
  date: Date | number | string,
  format: string
): string => {
  // Parse the input into objects
  const parsedDate = new Date(date);

  // Validate the parsed inputs
  if (isNaN(parsedDate.getTime())) {
    throw new Error(`Invalid date: ${date}`);
  }

  // Create a new UTC-based Date instance using Date.UTC
  const utcDate = new Date(
    Date.UTC(
      parsedDate.getUTCFullYear(),
      parsedDate.getUTCMonth(),
      parsedDate.getUTCDate(),
      parsedDate.getUTCHours(),
      parsedDate.getUTCMinutes(),
      parsedDate.getUTCSeconds(),
      parsedDate.getUTCMilliseconds()
    )
  );

  // Helper function to pad numbers with leading zeros
  const pad = (num: number) => String(num).padStart(2, "0");

  // Extract date components in UTC
  const year = utcDate.getUTCFullYear();
  const month = pad(utcDate.getUTCMonth() + 1); // Month is zero-based
  const day = pad(utcDate.getUTCDate());
  const hours = pad(utcDate.getUTCHours());
  const minutes = pad(utcDate.getUTCMinutes());
  const seconds = pad(utcDate.getUTCSeconds());

  // Month names for the "MMM" placeholder
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const monthName = monthNames[utcDate.getUTCMonth()];

  // Replace placeholders in the format string with corresponding values
  return format
    .replace("YYYY", String(year))
    .replace("MMM", monthName)
    .replace("MM", month)
    .replace("DD", day)
    .replace("HH", hours)
    .replace("mm", minutes)
    .replace("ss", seconds);
};
