import { Candidate } from "../models";
import {
  FETCH_BOOKER_CONFIGS_SUCCESS,
  FETCH_PASSAGE_CANDIDATES_SUCCESS,
  CREATE_BOOK_REQUEST_SUCCESS,
  FETCH_BOOKER_ERROR,
  FETCH_BOOKER_START,
  FETCH_BOOKER_END,
  FETCH_BOOKER_SUCCESS,
  FETCH_BOOKER_CANDIDATES_END,
  FETCH_BOOKER_CANDIDATES_START,
  SET_BOOKER_CONFIG,
  FETCH_BOOKED_AND_AVAILABLE_PASSAGES_SUCCESS,
  SET_DATE_RANGE,
  FETCH_PRESETS_SUCCESS
} from "../actions/types";

const initialState: any = {
  allPassages: [],
  candidates: [],
  bookerConfigs: [],
  bookedPassages: [],
  groundStationConfigs: [],
  pagination: [],
  config: null,
  presets: null,
  daterange: {},
  status: {
    candidates: null,
    config: null,
    book: null,
    sync: null
  }
};

export const passageManagerReducer = (
  state = initialState,
  action: { type: any; payload: any; pagination: any }
) => {
  switch (action.type) {
    case SET_BOOKER_CONFIG:
      const lastFetchedCandidates = state.allPassages;
      const isSameConfig =
        state.candidates?.bookingConfigId === action.payload?.id;
      return {
        ...state,
        config: action.payload,
        allPassages: isSameConfig ? lastFetchedCandidates : []
      };
    case FETCH_PRESETS_SUCCESS:
      return { ...state, presets: action.payload };
    case FETCH_BOOKER_START:
      return { ...state, status: action.payload };
    case FETCH_BOOKED_AND_AVAILABLE_PASSAGES_SUCCESS:
      return { ...state, allPassages: action.payload };
    case FETCH_BOOKER_ERROR:
      return { ...state, status: action.payload };
    case FETCH_BOOKER_END:
      return { ...state, status: action.payload };
    case FETCH_BOOKER_CANDIDATES_START:
      return { ...state, status: action.payload };
    case FETCH_BOOKER_CANDIDATES_END:
      return { ...state, status: action.payload };
    case FETCH_BOOKER_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        status: { config: "end" }
      };
    case FETCH_BOOKER_CONFIGS_SUCCESS:
      return {
        ...state,
        bookerConfigs: action.payload,
        groundStationConfigs: action.payload.groundStationConfigs,
        status: { config: "end" }
      };
    case FETCH_PASSAGE_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        status: { config: "end" }
      };
    case CREATE_BOOK_REQUEST_SUCCESS:
      return {
        ...state,
        bookedPassages: action.payload,
        candidates: updateCandidates(state.candidates, action.payload),
        status: { config: "end" }
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        daterange: action.payload
      };
    default:
      return state;
  }
};

function updateCandidates(candidates: any, bookingReqResponse: Candidate[]) {
  const _candidates = Array.isArray(candidates?.data) ? candidates.data : [];
  const updatedCandidates = { ...candidates, data: [..._candidates] };

  bookingReqResponse.forEach((bookingRes: any) => {
    const { candidateId, passage, responseCodeText } = bookingRes;

    candidates?.data?.forEach((candidate: Candidate) => {
      if (candidate.passageID === candidateId) {
        candidate.status = responseCodeText;
        candidate.additionalInfo.passage = passage;
      }
    });
  });
  return updatedCandidates;
}
