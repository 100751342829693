import produce, { Draft, original } from "immer";
import {
  IState,
  TelemetryDataReducerActions,
  FetchTelemetryActionTypes
} from "../models/telemetry";
import { MockWorker } from "./tests/__mocks__/worker";
import { isBrowser } from "../utils/validators";
import { updateTMOnWorkerMessage } from "../actions";

export const initialState: IState = {
  currentReading: {},
  historicalReadings: {},
  config: {
    from: "",
    to: "",
    timeReference: "satelliteUtc",
    windowSize: 1
  }
};

// use mock worker during testing
export const worker = isBrowser
  ? new Worker(new URL("./graph.worker.js", import.meta.url))
  : new MockWorker("");

worker.onmessage = (event: MessageEvent) => updateTMOnWorkerMessage(event);
worker.onerror = (event: MessageEvent) => console.log(event);

export const telemetryDataReducer = (
  state = initialState,
  action: TelemetryDataReducerActions
) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case FetchTelemetryActionTypes.UPDATE_HISTORICAL_TM_DATA_BY_WORKER:
        // update store with transformed data from worker
        return {
          ...state,
          historicalReadings: action.payload.historicalReadings
        };
      case FetchTelemetryActionTypes.UPDATE_TM_DATA:
        action.payload.forEach((update) => {
          draft.currentReading[update.dataSource.id] = update;
        });
        break;
      case FetchTelemetryActionTypes.UPDATE_HISTORICAL_TM_DATA:
        // offload expensive datatransformation to graph worker
        worker.postMessage({
          state,
          payload: action.payload.payload
        });
        return state;
      default:
        break;
    }
  });
