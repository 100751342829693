import { connect } from "react-redux";
import { SatelliteDefinitionEdit } from "../components/SatelliteDefinitionEdit";
import { getDataSourceUnits } from "app/dataSource";
import { withRouter } from "react-router-dom";
import { setFeedback } from "app/feedback/actions";
import { getTLE } from "../actions/tle";

const createSatelliteIdMap = data => {
  if (!data) return {};
  return data.reduce((acc, { satelliteDefinitionSummary: { satelliteDefinitionId }, id }) => {
    acc[satelliteDefinitionId] = id;
    return acc;
  }, {});
};

const mapStateToProps = (state) => ({
  tle: state.satellite.dashboard.tle,
  satIdMap: createSatelliteIdMap(state?.constellations?.selected?.satelliteInstances)
});

const mapDispatchToProps = (dispatch) => {
  return {
    getTLEBySatId: (satelliteId) => dispatch(getTLE(satelliteId)),
    getDataSourceUnits: () => getDataSourceUnits(),
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const SatelliteDefinitionEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SatelliteDefinitionEdit)
);
