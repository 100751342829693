export const FETCH_SATELLITE_START = "FETCH_SATELLITE_START";
export const FETCH_SATELLITE_SUCCESS = "FETCH_SATELLITE_SUCCESS";
export const FETCH_SATELLITE_ERROR = "FETCH_SATELLITE_ERROR";
export const SELECT_SYSTEM = "SELECT_SYSTEM";
export const SELECT_DATASOURCES = "SELECT_DATASOURCES";

export const GET_ORBITS_START = "GET_ORBITS_START";
export const GET_ORBITS_SUCCESS = "GET_ORBITS_SUCCESS";
export const GET_ORBITS_ERROR = "GET_ORBITS_ERROR";

export const GET_TLE_BY_SAT_ID = "GET_TLE_BY_SAT_ID";
export const SET_TLE_BY_SAT_ID = "SET_TLE_BY_SAT_ID";
