import { displayAlertInfo } from "./alertInfo";
import { STYLES } from "./tooltip";
import uPlot from "uplot";

export function clickListener(u: uPlot) {
  const graph = u.root.querySelector(".u-over");
  if (!graph) return;
  // @ts-ignore
  graph.addEventListener("click", (e: MouseEvent) => handleClick(e, u));
}

function filterByTimestamp(data: any, timestampValue: any) {
  const timestampKeys = [
    "sendTimestamp",
    "receiveTimestamp",
    "onboardTimeMillis"
  ];
  const result = data.map((entry: any) => {
    const filteredEntry: any = {};
    Object.entries(entry).forEach(([key, value]: any) => {
      const filteredValues = Object.entries(value).reduce(
        (acc, [innerKey, innerArray]: any) => {
          const filteredArray = innerArray?.filter((item: any) => {
            if (!item || typeof item !== "object") return false;
            return timestampKeys.some(
              (tsKey) => item[tsKey] === timestampValue
            );
          });
          if (filteredArray?.length > 0) {
            // @ts-ignore
            acc[innerKey] = filteredArray;
          }
          return acc;
        },
        {}
      );

      if (Object.keys(filteredValues).length > 0) {
        filteredEntry[key] = filteredValues;
      }
    });
    return Object.keys(filteredEntry).length > 0 ? filteredEntry : null;
  });
  return result.filter(Boolean);
}

/*
Track cursor position to display alert-info when cursor
is over a specific point.
Since alert-info is a not a plottable series but still available on canvas,
we have to extract the alert-info array and find the alert at given timestamp

*/
function handleClick(e: MouseEvent, u: uPlot) {
  const { left } = u.cursor;
  if (left === null) return;
  // Left most will start with 0 e.g [20,23,12]
  const indexOfCurrentValueOnSeries = u.posToIdx(left || 0);
  // do we need to allow distint points on veritical line??
  // const valuesAtTimestamp = [... new Set(u.data.slice(1).map((series) => series?.[indexOfCurrentValueOnSeries]))].filter(Boolean);
  // const v = u.valToPos(valuesAtTimestamp[0] || 0, "y")
  // LAST ARRAY is the alert-info, it is not plotted but accessible on last index of u.data
  const alertInfoSeriesIdx = u.data?.slice(-1);
  const info = Array.isArray(alertInfoSeriesIdx[0]) && [
    alertInfoSeriesIdx[0][indexOfCurrentValueOnSeries]
  ];
  showTooltip(u, info);
}

const isObject = (value: any) =>
  value !== null && typeof value === "object" && !Array.isArray(value);

function showTooltip(u: uPlot, alertDetails: any) {
  const alertTooltip = u.root.querySelector(".uplot-tooltip") as HTMLElement;
  if (!alertTooltip) return;
  const overlayEl = u.over;
  overlayEl.style.overflow = "visible";
  overlayEl.appendChild(alertTooltip);

  alertTooltip.classList.add("tooltip-hover");
  Object.assign(alertTooltip.style, STYLES);
  // TODO: create slider ui on the tooltip when alert list is too long
  alertTooltip.innerHTML = displayAlertInfo(alertDetails);

  positionTooltip(u, alertTooltip);
  alertTooltip.style.display = "block";
}

function hideTooltip(u: uPlot) {
  const alertTooltip = u.root.querySelector(".uplot-tooltip") as HTMLElement;
  if (alertTooltip) {
    alertTooltip.style.display = "none";
  }
}

function positionTooltip(u: uPlot, tooltip: HTMLElement) {
  const graph = u.root.querySelector(".u-over") as HTMLElement;
  if (!graph) return;

  const { left: cLeft, top: cTop } = u.cursor;
  const graphDim = graph.getBoundingClientRect();
  const tooltipDim = tooltip.getBoundingClientRect();

  // @ts-ignore
  const isNearGraphEdge = cLeft > graphDim.width * 0.8;
  const shiftY = 10;
  let shiftX = 20;
  if (isNearGraphEdge) {
    shiftX = -(tooltipDim.width + 20);
  }
  const left = Math.max(
    0,
    // @ts-ignore
    Math.min(cLeft + shiftX, graphDim.width - tooltipDim.width)
  );
  const top = Math.max(
    0,
    // @ts-ignore
    Math.min(cTop - shiftY, graphDim.height - tooltipDim.height)
  );
  tooltip.style.transform = `translate(${left}px, ${top}px)`;
}
