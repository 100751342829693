import { visibilityDataProvider } from "app/network";
import { OperationType } from "app/network/models/constants";
import { Passage } from "../models";
import { store } from "app/store";

export const fetchPassages = async (
  satelliteID: number
): Promise<Passage[]> => {
  const response = await visibilityDataProvider(
    OperationType.GET_LIST,
    `satellites/${satelliteID}/passages/current`
  );

  // Instead of fetching latest time from server, use time from store which updates every 30 seconds
  // const now = await getServerTime();
  const now = store.getState().visibilityWindow.serverTime;
  // Filtering passages where AOS < now < LOS
  return response.data.filter(
    (passage: Passage) =>
      now >= new Date(passage.aos) && now <= new Date(passage.los)
  );
};

export const fetchNextPassages = async (
  satelliteID: number
): Promise<Passage[]> => {
  const response = await visibilityDataProvider(
    OperationType.GET_LIST,
    `satellites/${satelliteID}/passages/next`
  );
  return response.data;
};

export const fetchAllPassages = async (
  sat_ids: number[],
  begin: string | Date,
  end: string | Date
): Promise<Passage[]> => {
  try {
    const sanitizedSatIdList = sat_ids?.filter(Boolean).toString() || []
    const response = await visibilityDataProvider(
      OperationType.GET_LIST,
      `passages`,
      { filter: { begin, end, sat_ids: sanitizedSatIdList } }
    );
    return response.data;
  } catch (err) {
    return [];
  }
};
