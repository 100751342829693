import { Dispatch } from 'redux';
import { getTLEBySatId, setTLEBySatId } from '../services/tle';
import { GET_TLE_BY_SAT_ID, SET_TLE_BY_SAT_ID } from 'app/satellite/models/constants';

export const getTLE = (satelliteId: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const result = await getTLEBySatId(satelliteId);
            dispatch({
                type: GET_TLE_BY_SAT_ID,
                payload: result
            });
            return Promise.resolve(result);
        } catch (e) {
            return Promise.resolve([]);
        }
    };
};

export const setTLE = (satelliteId: string, data: any) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data: result } = await setTLEBySatId(satelliteId, data);
            dispatch({
                type: SET_TLE_BY_SAT_ID,
                payload: result
            });
            return Promise.resolve(result);
        } catch (e) {
            return Promise.resolve([]);
        }
    };
};

