import React from "react";
import localFormatter from "date-fns/format";
import { dateFormats, utcFormatter } from "./helpers";
import { Text } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

interface DateTimeLabelProps extends TestableProps {
  value: Date | string;
  format?: string;
  fontSize?: number;
  color?: string;
  inUtc: boolean;
}

const DateTimeLabel = ({
  value,
  format,
  fontSize,
  color,
  inUtc
}: DateTimeLabelProps) => {
  let formattedDate: string;

  try {
    const formatSelected = (format && dateFormats[format]) || format!;

    formattedDate = inUtc
      ? utcFormatter(value, formatSelected)
      : localFormatter(value, formatSelected);
  } catch (error) {
    // Fallback if error occur
    console.error(error);
    formattedDate = "Invalid Date";
  }

  return (
    <Text fontSize={fontSize} color={color}>
      {formattedDate}
    </Text>
  );
};

DateTimeLabel.displayName = "DateTimeLabel";

DateTimeLabel.defaultProps = {
  format: "short",
  fontSize: 5,
  color: "palette.brand.0",
  inUtc: false,
  [DATA_TEST_ID_ATTR_NAME]: DateTimeLabel.displayName
};

export default DateTimeLabel;
