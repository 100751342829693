import React, { Component } from "react";
import { TabInput, Form } from "app/shared";
import { SystemTreeInput } from "app/systemDefinition";
import { FrameDefinitionEditContainer } from "app/frameDefinition";
import { Box, Heading, Flex, Text } from "primitives";
import { getSatelliteDefinition, updateSatelliteDefinition } from "../services";
import { getTLEBySatId } from "../services/tle";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { schemaEdit, uiSchemaEdit } from "../schemas";
import { SaveButton, CancelButton } from "components";
import { FeedbackStatus } from "app/feedback/models";
import { themeGet } from "styled-system";

const tabLabels = ["Details", "Systems and Datasources"];

export class SatelliteDefinitionEdit extends Component {
  state = {
    formData: null,
    tle: null
  };

  componentDidMount() {
    const { satIdMap } = this.props;
    const { id } = this.props.match.params;
    this.props.getTLEBySatId(satIdMap[id])
    this.props.getDataSourceUnits({ page: 1, perPage: 100 }); // ask for a list of units
  }

  cancel = () => {
    this.props.history.push("/satellite-definition");
  };

  submit(e, { formData }) {
    e.preventDefault();
    const { satIdMap } = this.props;
    const extendedFormData = {
      ...formData,
      tle: {
        tle: formData.tle,
        satelliteID: satIdMap[formData.id]
      }
    };
    return updateSatelliteDefinition(extendedFormData)
      .then(() => {
        this.cancel();
      })
      .catch(() => {
        this.props.setFeedback("Unauthorized", FeedbackStatus.ERROR);
      });
  }

  query = () => {
    const { id } = this.props.match.params;
    const { satIdMap } = this.props;
    return Promise.all([
      getSatelliteDefinition(id),
      getTLEBySatId(satIdMap[id])
    ]).then(([satelliteDefinition, tle]) => {
      return { ...satelliteDefinition, tle: tle?.tle };
    });
  };

  renderDetails = (response) => {
    const tle = this.props.tle;
    const { formData } = this.state;

    return (
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => {
            return (
              <Form
                formData={formData ? formData : response}
                schema={schemaEdit}
                uiSchema={uiSchemaEdit}
                disabled={loading}
                onChange={(form, event) =>
                  this.setState({ formData: form.formData })
                }
                onSubmit={(data, e) => action(() => this.submit(e, data))}
              >
                <SaveButton type="submit" disabled={loading}>
                  Save
                </SaveButton>
                <CancelButton onClick={this.cancel} disabled={loading} mx={1}>
                  Cancel
                </CancelButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">Error...</Text>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </SuspenseMutation>
      </Flex>
    );
  };

  render() {
    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response, reload, error }) => {
            return (
              response && (
                <Flex
                  flexDirection="column"
                  height="100%"
                  color="text.default"
                  data-testid="SatelliteDefinitionEdit"
                  overflow="visible"
                  mx={3}
                >
                  <Flex mb={2} overflow="visible">
                    <Heading display={1}>Satellite definition</Heading>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    overflow="visible"
                    height="100%"
                    mt={2}
                    py={3}
                  >
                    <TabInput
                      record={response}
                      tabs={tabLabels}
                      color={themeGet("colors.text.default")}
                      containers={(value, record) => (
                        <>
                          {value === 0 && this.renderDetails(response, reload)}
                          {value === 1 && (
                            <SystemTreeInput record={record} height="100%" />
                          )}
                          {value === 2 && (
                            <FrameDefinitionEditContainer record={record} />
                          )}
                        </>
                      )}
                    />
                  </Flex>
                </Flex>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}
