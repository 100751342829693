import { store } from "app/store";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import HttpError from "./HttpError";

class RequestManager {
  static instance: any;
  static cachableResources = ["satellites"];

  requests: Map<any, any> = new Map();
  constructor() {
    if (RequestManager.instance) {
      return RequestManager.instance;
    }
    RequestManager.instance = this;
    this.requests = new Map();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RequestManager();
    }
    return this.instance;
  }

  shouldCache(url: string, options: Options) {
    if (this.isMutation(options)) {
      this.remove(url)
      return false
    }
    const cacheList = RequestManager.cachableResources;
    const isCachable = (url: string) =>
      cacheList.some((u: string) => url.includes(u));

    return isCachable(url);
  }

  getToken(options: Options) {
    const tokenString = options.headers.get("Authorization");
    if (tokenString) {
      return tokenString.replace(/^Bearer\s+/i, "");
    }
    return null;
  }

  isCached(url: string, options: Options) {
    if (this.isMutation(options)) {
      this.remove(url)
      return false
    }
    const key = this.makeCacheKey(url, options);
    return this.requests.has(key);
  }

  getCached(url: string, options: Options) {
    const key = this.makeCacheKey(url, options);
    if (this.isCached(url, options)) {
      console.info("RM ~ return from cache", url);
      return this.requests.get(key);
    }
  }

  makeCacheKey(url: string, options: Options) {
    const token = this.getToken(options);
    if (token) {
      return `${url}-${token}`;
    }
    console.warn("RM ~ token not found");
    return null;
  }

  setCache(url: string, options: Options, data: any) {
    if (this.isMutation(options)) {
      this.remove(url)
      return false
    }
    if (this.shouldCache(url, options)) {
      const key = this.makeCacheKey(url, options);
      if (key) {
        console.info("RM ~ setting cache", url);
        return this.requests.set(key, data);
      }
    }
    return null;
  }

  add(url: string, controller: AbortController, options: Options) {
    if (this.isMutation(options)) {
      this.remove(url)
      return false
    }
    this.requests.set(url, controller);
  }

  get(url: string) {
    this.requests.get(url);
  }

  cancel(url: string) {
    const controller = this.requests.get(url);
    controller.abort("Duplicated request aborted.");
  }

  remove(url: string) {
    this.requests.delete(url);
  }

  isMutation(options: Options) {
    return options.method === "POST" || options.method === "PUT";
  }

  inProgress(url: string, options: Options) {
    if (this.isMutation(options)) {
      this.remove(url)
      return false
    }
    return this.requests.get(url) !== undefined;
  }

  handleResponse(
    response: Response,
    text: string,
    url: string,
    options: Options
  ) {
    this.remove(url);
    const data = {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      json: (text && this.parseJson(text)) || { data: text },
      url
    };

    this.isPassagesReq(url) && this.handlePassagesReq(data);
    this.handleHttpErrors(data);
    this.setCache(url, options, data);
    return data;
  }

  handlePassagesReq(data: any) {
    const { url } = data;
    const satIds = new URLSearchParams(url).get("sat_ids")?.split(",") || [];
    store.dispatch(
      setFeedback(
        `Fetching passages for sat ids: ${satIds}`,
        FeedbackStatus.SUCCESS
      )
    );
  }

  isPassagesReq = (url: string) =>
    url.includes("/windows/passages?") && url.includes("sat_ids");

  handleHttpErrors = (data: any) => {
    const { url, status, json, statusText } = data;
    if (status < 200 || status >= 300) {
      // REMOVE WHEN FIXED FROM LEAF
      const _json =
        json?.data === "Illegal input" ? { data: "AuthZ Error" } : json;
      if (_json?.detail === 'Resource does not exist') {
        // REMOVE WHEN TLE IS MOVED TO NEW SERVICE
        return
      }
      this.showFeedback({ url, status, json: _json });

      return Promise.reject(
        new HttpError((json && json.message || json?.detail) || statusText, status, json)
      );
    }
  };

  parseJson = (body: string) => {
    let json;
    try {
      json = JSON.parse(body);
      return json;
    } catch (e) {
      console.log("Error: response body is not valid json", e);
    }
  };

  showFeedback = ({
    url,
    status,
    json
  }: {
    url: string;
    status?: number;
    json?: any;
  }) => {
    this.validHttpError({
      url,
      status,
      json
    }) &&
      store.dispatch(
        setFeedback(
          `Error: ${json?.data || json?.message || json?.detail || ""}`,
          FeedbackStatus.ERROR,
          `${url} status: ${status}`
        )
      );
  };

  handleError = (error: HttpError, url: string) => {
    this.remove(url);

    if (error.name === "AbortError") {
      console.warn("Request aborted.");
    } else {
      console.log("fetchJson ~ error:", url, error);
    }
  };

  validHttpError = (err: any) => {
    if (err?.json || err?.json?.message || (err?.json?.data && err?.status)) {
      return true;
    }
    return false;
  };
}
// assign alias to extend this type if needed without requiring changes above
type Options = Request;
// Use only a single instance across the app
const requestManager = RequestManager.getInstance();

export { requestManager };
