import { plotAlerts } from "./alerts";

export const GRAPH_DIV_ID = ".uplot-tooltip";

export const CONFIG = {
  color: "red",
  defaultWidth: 0.5,
  selectedWidth: 2,
  clickRange: 20 // range around alert line which can be clickable
};

export const alertSeriesConfig = (label: string) => {
  return {
    auto: true,
    label: label,
    width: 4.5,
    spanGaps: true,
    points: {
      fill: "red",
      show: plotAlerts
    },
    stroke: "transparent",
    drawStyles: {
      points: 2
    }
  };
};

export const alertInfoSeriesConfig = (label: string) => {
  return {
    show: false,
    label: `${label}`,
    class: "alert-info",
    auto: false,
    points: {
      show: false
    }
  };
};
