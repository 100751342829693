import { SelectBox, InputBase } from "app/shared";

export const bookSchemaCreate = (presets?: any) => {
  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "book.schema.json",
    title: "book a candidate passage",
    name: "book a candidate passage",
    type: "object",
    properties: {
      presets: {
        type: "string",
        title: "Select a preset to use for passage",
        enum: presets?.map((p: { id: any }) => p.id),
        enumNames: presets?.map((p: { name: any }) => p.name)
      },
    }
  };
};

export const bookSchemaEdit = (presets?: any[]) => {
  return {
    type: "object",
    properties: {
      id: {
        type: "number",
        title: "Id",
        kind: "constant"
      },
      ...bookSchemaCreate(presets).properties
    }
  };
};

export const bookSchemaDefault = {
  presets: "DEFAULT"
};

export const bookSchemaUISchema = {
  presets: { "ui:widget": SelectBox },
};

export const bookSchemaUISchemEdit = {
  id: { "ui:widget": "hidden" },
  ...bookSchemaUISchema
};
