export const FETCH_BOOKER_START = "FETCH_BOOKER_START";
export const FETCH_BOOKER_END = "FETCH_BOOKER_END";
export const FETCH_BOOKER_CANDIDATES_START = "FETCH_BOOKER_CANDIDATES_START";
export const FETCH_BOOKER_CANDIDATES_END = "FETCH_BOOKER_CANDIDATES_END";
export const FETCH_BOOKER_SUCCESS = "FETCH_BOOKER_SUCCESS";
export const FETCH_BOOKER_ERROR = "FETCH_BOOKER_ERROR";
export const FETCH_BOOKER_CONFIGS_SUCCESS = "FETCH_BOOKER_CONFIGS_SUCCESS";
export const CREATE_BOOKER_CONFIG_SUCCESS = "CREATE_BOOKER_CONFIG_SUCCESS";
export const CREATE_BOOK_REQUEST_SUCCESS = "CREATE_BOOK_REQUEST_SUCCESS";
export const CREATE_BOOK_REQUEST_ERROR = "CREATE_BOOK_REQUEST_ERROR";
export const UPDATE_BOOKER_CONFIG_SUCCESS = "UPDATE_BOOKER_CONFIG_SUCCESS";
export const DELETE_BOOKER_CONFIG_SUCCESS = "DELETE_BOOKER_CONFIG_SUCCESS";
export const CANCEL_BOOK_REQUEST_SUCCESS = "CANCEL_BOOK_REQUEST_SUCCESS";
export const CANCEL_BOOK_REQUEST_ERROR = "CANCEL_BOOK_REQUEST_ERROR";
export const SET_BOOKER_CONFIG = "SET_BOOKER_CONFIG";
export const FETCH_PASSAGE_CANDIDATES_SUCCESS =
  "FETCH_PASSAGE_CANDIDATES_SUCCESS";
export const FETCH_BOOKED_AND_AVAILABLE_PASSAGES_SUCCESS =
  "FETCH_BOOKED_AND_AVAILABLE_PASSAGES_SUCCESS";
export const FETCH_BOOKED_PASSAGES_ERROR = "FETCH_BOOKED_PASSAGES_ERROR";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const FETCH_PRESETS_SUCCESS = "FETCH_PRESETS_SUCCESS";
export const UPDATE_PASSAGE = "UPDATE_PASSAGE";
